<template>
  <v-container fluid>
    <v-data-table
      :items="items"
      :headers="headers"
      :search="search"
      :no-data-text="$t('commonTable.noDataText')"
      :footer-props="{ itemsPerPageText: $t('commonTable.perPageText') }"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Registros</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Buscar"
          />
        </v-toolbar>
      </template>
      <template
        v-slot:[`item.more`]="{ item }"
      >
        <v-icon
          :disabled="!(userRole === 'superadmin' || (item.flowBot === userRole) || (userRole === 'facilitador' && item.flowBot === 'ecobotin'))"
          small
          class="mr-2"
          @click="openDialog(item)"
        >
          fas fa-edit
        </v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-switch
          v-if="!(item.roleType == 'superadmin')"
          v-model="item.status"
          @click="openDialogAgree()"
        />
        <v-dialog
          v-model="dialogAgree"
          max-width="290"
          :retain-focus="false"
        >
          <v-card>
            <v-card-title class="text-h5">
              Estas seguro que quieres desactivar a este usuario?
            </v-card-title>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="green darken-1"
                text
                @click="closeDialogAgree()"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="sendStatus(item)"
              >
                Estoy seguro
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatShowDate(item.createdAt) }}
      </template>
    </v-data-table>
    <form-user
      :text-field="inputData"
      :dialog="dialog"
      title="Editar Usuario"
      :data-info="sendEditData"
      v-on="$listeners"
      @closeDialog="dialog = false"
    />
  </v-container>
</template>

<script>

import dateFormatMixin from '@/mixins/dateFormat';

import FormUser from './FormUser.vue';

export default {
  name: 'TableUsers',
  components: { FormUser },
  mixins: [dateFormatMixin],
  props: {
    headers: {
      type: Array,
      default: ()=>([]),
      optional: true,
    },
    items: {
      type: Array,
      default: ()=>([]),
      optional: true,
    },
    inputData: {
      type: Array,
      default: ()=>([]),
      optional: true,
    },

  },
  data() {
    return {
      dialogAgree: false,
      search: '',
      dialog: false,
      sendEditData: {},
    };
  },
  computed: {
    objeto() {
      return this.sendEditData;
    },
    userRole() {
      return JSON.parse(localStorage.getItem('user')).role;
    },
  },
  methods: {
    openDialog(dataEdit) {
      // console.log(dataEdit);
      this.sendEditData = Object.assign({}, dataEdit);
      this.dialog = true;
      this.$emit('datos', 'click');
    },
    openDialogAgree() {
      this.dialogAgree = true;
    },
    closeDialogAgree() {
      this.dialogAgree = false;
    },
    sendStatus(data) {
      this.$emit('edit', { id: data.id, status: data.status });
      this.$off('edit', { id: data.id, status: data.status });
      this.closeDialogAgree();
    },
  },
};
</script>

<style>
.TableUser{
    border-left: 0.5px solid #f0f2f7;
    border-bottom: 0.5px solid #f0f2f7;
}
</style>
