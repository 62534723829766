<template>
  <v-toolbar
    color="indigo"
    dark
    flat
  >
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-btn
      icon
      color="green"
      @click="updateData()"
    >
      <v-icon color="white">
        fas fa-sync-alt
      </v-icon>
    </v-btn>
    <v-spacer />
    <v-btn
      v-if="userRole === 'superadmin'"
      dark
      @click="dialog = true"
    >
      <v-icon left>
        fas fa-plus
      </v-icon>
      {{ btntext }}
    </v-btn>
    <form-user
      v-if="userRole === 'superadmin'"
      :text-field="inputData"
      :dialog="dialog"
      title="Crear Usuario"
      :data-info="dataObject"
      v-on="$listeners"
      @closeDialog="dialog = false"
    />
  </v-toolbar>
</template>

<script>
import FormUser from './FormUser.vue';
/* agregar save() no olvidar*/


export default {
  name: 'HeaderPageTable',
  components: { FormUser },
  props: {
    updateData: {
      type: Function,
      default: () => {},
      optional: true,
    },
    saveData: {
      type: Function,
      default: ()=> {},
      optional: true,
    }, title: {
      type: String,
      default: '',
      optional: true,
    }, btntext: {
      type: String,
      default: '',
      optional: true,
    },
    inputData: {
      type: Array,
      default: ()=>([]),
      optional: true,
    },
  },
  data() {
    return {
      dialog: false,
      dataObject: {},
    };
  },
  computed: {
    userRole() {
      return JSON.parse(localStorage.getItem('user')).role;
    },
  },
  mounted() {
    this.get;
  },
  method: {
    save() {
      this.saveData;
    },
  },
};
</script>
