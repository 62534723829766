<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="850px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-alert
        :value="snack"
        :type="snackColor"
      >
        {{ snackText }}
      </v-alert>
      <v-card-text>
        <v-container>
          <v-form
            v-if="dialog"
            ref="formUser"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col
                v-for="(textInput,index) in textField"
                :key="index"
                :cols="textInput.cols ? textInput.cols : 12"
              >
                <v-row v-if="textInput.type === 'cellphone' && textInput.hidden && textInput.id ==='supervisorPhone' && objectSend['vendedor'] === 'SI'">
                  <v-chip
                    class="ma-4"
                    color="primary"
                    label
                    text-color="white"
                    cols="2"
                  >
                    +52 1
                  </v-chip>
                  <v-text-field
                    :id="textInput.id"
                    v-model="objectSend[textInput.id]"
                    :label="textInput.text"
                    type="text"
                    :rules="objectSend['vendedor'] === 'SI' ? textInput.hidden.no:textInput.hidden.si"
                    placeholder="Numero 10 digitos"
                    cols="10"
                  />
                </v-row>
                <v-row v-if="textInput.type === 'cellphone' && textInput.hidden && textInput.id ==='facilitatorPhone' && objectSend['flowBot'] === 'ecobotin'">
                  <v-chip
                    class="ma-4"
                    color="primary"
                    label
                    text-color="white"
                    cols="2"
                  >
                    +52 1
                  </v-chip>
                  <v-text-field
                    :id="textInput.id"
                    v-model="objectSend[textInput.id]"
                    :label="textInput.text"
                    type="text"
                    :rules="objectSend['flowBot'] === 'ecobotin' ? textInput.hidden.no:textInput.hidden.si"
                    placeholder="Numero 10 digitos"
                    cols="10"
                  />
                </v-row>
                <v-row v-if="textInput.type === 'cellphone' && textInput.hidden && (textInput.id ==='inasePhone' || textInput.id === 'inaseMasterPhone') && objectSend['flowBot'] === 'inase'">
                  <v-chip
                    class="ma-4"
                    color="primary"
                    label
                    text-color="white"
                    cols="2"
                  >
                    +52 1
                  </v-chip>
                  <v-text-field
                    :id="textInput.id"
                    v-model="objectSend[textInput.id]"
                    :label="textInput.text"
                    type="text"
                    :rules="objectSend['flowBot'] === 'inase' ? textInput.hidden.no:textInput.hidden.si"
                    placeholder="Numero 10 digitos"
                    cols="10"
                  />
                </v-row>
                <v-row v-if="textInput.type === 'cellphone' && textInput.hidden && textInput.id != 'supervisorPhone' && objectSend['flowBot'] === 'ambos'">
                  <v-chip
                    class="ma-4"
                    color="primary"
                    label
                    text-color="white"
                    cols="2"
                  >
                    +52 1
                  </v-chip>
                  <v-text-field
                    :id="textInput.id"
                    v-model="objectSend[textInput.id]"
                    :label="textInput.text"
                    type="text"
                    :rules="objectSend['flowBot'] === 'ambos' ? textInput.hidden.no:textInput.hidden.si"
                    placeholder="Numero 10 digitos"
                    cols="10"
                  />
                </v-row>
                <v-row v-if="textInput.type === 'cellphone' && !textInput.hidden ">
                  <v-chip
                    class="ma-4"
                    color="primary"
                    label
                    text-color="white"
                    cols="2"
                  >
                    +52 1
                  </v-chip>
                  <v-text-field
                    :id="textInput.id"
                    v-model="objectSend[textInput.id]"
                    :label="textInput.text"
                    type="text"
                    :rules="objectSend['vendedor'] === 'SI' && textInput.id === 'cellphone' ? [] : textInput.rules"
                    placeholder="Numero 10 digitos"
                    cols="10"
                  />
                </v-row>
                <v-text-field
                  v-if="textInput.type === 'password'"
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="textInput.rules"
                  :label="textInput.text"
                  type="password"
                />
                <div v-if="textInput.id === 'password' && passwordComplexityValidation">
                  {{ passwordComplexityValidation.strength }}
                  <v-rating
                    background-color="grey"
                    :color="passwordComplexityValidation.color"
                    length="5"
                    readonly
                    size="20"
                    :value="passwordComplexityValidation.value"
                  />
                  <small v-html="passwordComplexityValidation.message"></small>
                </div>
                <v-select
                  v-if="textInput.type === 'select' && textInput.id != 'flowBot'"
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :items="textInput.items"
                  :label="textInput.text"
                  :rules="textInput.rules"
                />
                <v-select
                  v-if="textInput.type === 'select' && textInput.id === 'flowBot' && userRole === 'superadmin' "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :items="textInput.items"
                  :label="textInput.text"
                  :rules="textInput.rules"
                />
                <v-text-field
                  v-if="textInput.type === 'text' && textInput.hidden && textInput.id === 'supervisorName' && objectSend['vendedor'] =='SI' "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="objectSend['vendedor'] =='SI' ? textInput.hidden.no:textInput.hidden.si "
                  :label="textInput.text"
                  type="text"
                />
                <v-text-field
                  v-if="textInput.type === 'text' && textInput.hidden && textInput.id === 'facilitatorName' && objectSend['flowBot'] =='ecobotin' "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="objectSend['vendedor'] =='SI' ? textInput.hidden.no:textInput.hidden.si "
                  :label="textInput.text"
                  type="text"
                />
                <v-text-field
                  v-if="textInput.type === 'text' && textInput.hidden && textInput.id === 'inaseName' && objectSend['flowBot'] =='inase' "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="objectSend['vendedor'] =='SI' ? textInput.hidden.no:textInput.hidden.si "
                  :label="textInput.text"
                  type="text"
                />
                <v-text-field
                  v-if="textInput.type === 'text' && textInput.hidden && textInput.id === 'inaseMasterName' && objectSend['flowBot'] =='inase' "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="objectSend['vendedor'] =='SI' ? textInput.hidden.no:textInput.hidden.si "
                  :label="textInput.text"
                  type="text"
                />
                <v-text-field
                  v-if="textInput.type === 'text' && textInput.hidden && textInput.id != 'supervisorName' && objectSend['flowBot'] =='ambos' "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="objectSend['vendedor'] =='SI' ? textInput.hidden.no:textInput.hidden.si "
                  :label="textInput.text"
                  type="text"
                />
                <v-text-field
                  v-if="(textInput.type === 'text' && !textInput.hidden) "
                  :id="textInput.id"
                  v-model="objectSend[textInput.id]"
                  :rules="textInput.rules"
                  :label="textInput.text"
                  type="text"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="closeContext"
        >
          Cerrar
        </v-btn>

        <v-btn
          color="blue darken-1"
          text
          @click="dataInfoExist ? edit() : save()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


export default {
  name: 'FormUser',
  props: {
    dialog: { // Estado del Modal {True o False}
      type: Boolean,
      default: false,
      optional: false,
    },
    dataInfo: { // Datos del usuario que se van a modificar
      type: Object,
      default: ()=>({}),
    },
    title: { // Titulo en la parte superior
      type: String,
      default: '',
    },
    textField: { // Arreglo para crear el form dependiendo de los textfiel necesarios
      type: Array,
      default: ()=>([]),
      optional: false,
    },
  },
  data() {
    return {
      snack: false,
      snackColor: 'success',
      snackText: '',
      valid: true,
      objectSend: {},
      newObjectSend: {},
      dataInfoExist: false,
      renderComponent: true,
    };
  },
  computed: {
    passwordComplexityValidation() {
      if (!this.objectSend.password) return;
      // TextBox left blank.
      if (this.objectSend.password.length === 0) {
        return;
      }

      let message = '';

      // Regular Expressions.
      const regex = [];
      regex.push('[A-Z]'); // Uppercase Alphabet.
      regex.push('[a-z]'); // Lowercase Alphabet.
      regex.push('[0-9]'); // Digit.
      regex.push('[$@$!%*#?&]'); // Special Character.

      let passed = 0;

      // Validate for each Regular Expression.
      for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(this.objectSend.password)) {
          passed++;
        }
      }

      // Validate for length of Password.
      if (passed > 3 && this.objectSend.password.length > 8) {
        passed++;
      } else {
        message = `
        Una contraseña segura debe tener como mínimo<br/>
        1 letra minúscula, <br/>
        1 letra mayúscula, <br/>
        1 número y <br/>
        1 carácter especial: [$@$!%*#?&] <br/>
        y una longitud mínima de 8 carácteres 
        `;
      }

      // Display status.
      let color = '';
      let strength = '';
      switch (passed) {
        case 0:
        case 1:
          strength = 'Weak';
          color = 'red';
          break;
        case 2:
          strength = 'Good';
          color = 'orange';
          break;
        case 3:
        case 4:
          strength = 'Strong';
          color = 'yellow';
          break;
        case 5:
          strength = 'Very Strong';
          color = 'green';
          break;
      }
      return {
        color, strength, value: Number(passed), message,
      };
    },
    userRole() {
      return JSON.parse(localStorage.getItem('user')).role;
    },
    selectAs() {
      const value = this.objectSend;
      return value;
    },
  },
  watch: {
    dialog: function(val) {
      this.createObject();
    },
    // dataInfoExist: function(val) {
    //   console.log(val);
    // },
  },
  beforeUpdate() {
    this.newObject();
    // console.log(this.newObjectSend);
  },
  methods: {
    createObject() {
      if (!this.textField) {
        return;
      }
      if (this.dataInfo.cellphone) {
        this.dataInfo.cellphone = this.dataInfo.cellphone.replace('+521', '');
      }
      if (this.dataInfo.supervisorPhone) {
        this.dataInfo.supervisorPhone = this.dataInfo.supervisorPhone.replace('+521', '');
      }
      if (this.dataInfo.facilitatorPhone) {
        this.dataInfo.facilitatorPhone = this.dataInfo.facilitatorPhone.replace('+521', '');
      }
      if (this.dataInfo.inasePhone) {
        this.dataInfo.inasePhone = this.dataInfo.inasePhone.replace('+521', '');
      }
      if (this.dataInfo.inaseMasterPhone) {
        this.dataInfo.inaseMasterPhone = this.dataInfo.inaseMasterPhone.replace('+521', '');
      }

      /* for (let index = 0; index < this.textField.length; index++) {
        if (this.dataInfo[this.textField[index].id]) {
          this.objectSend[this.textField[index].id] = this.dataInfo[this.textField[index].id];
        }
      }*/
      this.objectSend = Object.assign({}, this.objectSend, this.dataInfo);
      this.objectSend.id = this.dataInfo.id;
      this.dataInfoExist = Object.keys(this.dataInfo).length > 0;
    },
    newObject() {
      for (let index = 0; index < this.textField.length; index++) {
        if (this.objectSend[this.textField[index].id]) {
          this.newObjectSend[this.textField[index].id] = this.objectSend[this.textField[index].id];
        }
      }
      this.newObjectSend.id = this.objectSend.id;
    },
    closeContext() {
      this.$refs.formUser.reset();
      this.$emit('closeDialog', false);
    },
    save() {
      if (this.$refs.formUser.validate()) {
        if (this.objectSend.password) {
          if (this.passwordComplexityValidation.value !== 5) {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = 'Por favor crea una contraseña segura.';
            return;
          }
          if (this.objectSend.password !== this.objectSend.confirmPassword) {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = 'La contraseña no coincide';
            return;
          }
        }
        if (this.newObjectSend.cellphone) {
          if (this.newObjectSend.cellphone.length > 0 ) {
            this.newObjectSend.cellphone = '+521' + this.newObjectSend.cellphone;
          }
        }
        if (this.newObjectSend.supervisorPhone) {
          if (this.newObjectSend.supervisorPhone.length > 0 ) {
            this.newObjectSend.supervisorPhone = '+521' + this.newObjectSend.supervisorPhone;
          }
        }
        if (this.newObjectSend.facilitatorPhone) {
          if (this.newObjectSend.facilitatorPhone.length > 0 ) {
            this.newObjectSend.facilitatorPhone = '+521' + this.newObjectSend.facilitatorPhone;
          }
        }
        if (this.newObjectSend.inasePhone) {
          if (this.newObjectSend.inasePhone.length > 0 ) {
            this.newObjectSend.inasePhone = '+521' + this.newObjectSend.inasePhone;
          }
        }
        if (this.newObjectSend.inaseMasterPhone) {
          if (this.newObjectSend.inaseMasterPhone.length > 0 ) {
            this.newObjectSend.inaseMasterPhone = '+521' + this.newObjectSend.inaseMasterPhone;
          }
        }
        this.$emit('save', this.newObjectSend);
        this.$off('save', this.newObjectSend);
        this.closeContext();
      }
    },
    edit() {
      if (this.$refs.formUser.validate()) {
        if (this.objectSend.password) {
          if (this.passwordComplexityValidation.value !== 5) {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = 'Por favor crea una contraseña segura.';
            return;
          }
          if (this.objectSend.password !== this.objectSend.confirmPassword) {
            this.snack = true;
            this.snackColor = 'error';
            this.snackText = 'La contraseña no coincide';
            return;
          }
        }
        if (this.newObjectSend.cellphone) {
          if (this.newObjectSend.cellphone.length > 0 ) {
            this.newObjectSend.cellphone = '+521' + this.newObjectSend.cellphone;
          }
        }
        if (this.newObjectSend.supervisorPhone) {
          if (this.newObjectSend.supervisorPhone.length > 0 ) {
            this.newObjectSend.supervisorPhone = '+521' + this.newObjectSend.supervisorPhone;
          }
        }
        if (this.newObjectSend.facilitatorPhone) {
          if (this.newObjectSend.facilitatorPhone.length > 0 ) {
            this.newObjectSend.facilitatorPhone = '+521' + this.newObjectSend.facilitatorPhone;
          }
        }
        if (this.newObjectSend.inasePhone) {
          if (this.newObjectSend.inasePhone.length > 0 ) {
            this.newObjectSend.inasePhone = '+521' + this.newObjectSend.inasePhone;
          }
        }
        if (this.newObjectSend.inaseMasterPhone) {
          if (this.newObjectSend.inaseMasterPhone.length > 0 ) {
            this.newObjectSend.inaseMasterPhone = '+521' + this.newObjectSend.inaseMasterPhone;
          }
        }
        this.$emit('edit', this.newObjectSend);
        this.$off('edit', this.newObjectSend);
        this.closeContext();
      }
    },

  },

};
</script>
